//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
      id: "sycbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "account",
        title: "数据库设置",
        name: "account",
        icon: "el-icon-document",
        component: "views/saiz/Account.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "type",
        title: "同步类型设置",
        name: "type",
        icon: "el-icon-document",
        component: "views/saiz/Type.vue"
        ,visible:true
      },
      {
        pageFlag: true,
        id: "detail",
        title: "同步明细设置",
        name: "detail",
        icon: "el-icon-document",
        component: "views/saiz/Detail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "syccommon",
        title: "基础资料同步",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "inventoryclass",
          title: "存货分类同步",
          name: "inventoryclass",
          icon: "el-icon-document",
          component: "views/saiz/InventoryClass.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "inventory",
          title: "存货同步",
          name: "inventory",
          icon: "el-icon-document",
          component: "views/saiz/Inventory.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "bom",
          title: "物料清单同步",
          name: "bom",
          icon: "el-icon-document",
          component: "views/saiz/Bom.vue"
          ,visible:true
        } 
      ]
      }
],
  } ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld